import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Token } from '../models/token.model';
import { UserDetalle } from 'app/shared/model/user-detalle.model';
import { LocalStoreService } from 'app/shared/services/local-storage.service';
import {Store} from '../../../pages/model/store';
import {Login} from '../../../pages/content-pages/model/login';
import {User} from '../../../pages/content-pages/model/user';

const API_GET_USER = environment.api_url + 'engine/inicio/';
/*const API_LOGIN_URL = environment.api_url + 'login/';*/
const API_LOGIN_URL = environment.api_url + '/login';

@Injectable()
export class AuthService {
  private readonly baseUrl = environment.api_url;
  private readonly loginUrl = '/login';
  constructor(
    private httpClient: HttpClient,
    private ls: LocalStoreService
  ) { }

  /*login(userName: string, password: string): Observable<Token> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    httpHeaders.set('No-Auth', 'True');*/

    /*var formData =
    { "userName": userName, "password": password, "clientId": environment.clientId, "clientSecret": environment.clientSecret };

    return this.http.post<Token>(API_LOGIN_URL, formData, { headers: httpHeaders });


    const formData = { 'username': userName, 'password': password };
    return this.http.post<Token>(API_LOGIN_URL, formData);
  }*/

  login(login: Login): Promise<User> {
    return new Promise(((resolve, reject) => {
      this.httpClient.post(`${this.baseUrl}${this.loginUrl}`, login)
        .subscribe(res => resolve(this.buildLogin(res)), error => reject(error));
    }));
  }

  buildLogin(response): User {
    return {
      id: response.user.id,
      username: response.user.username,
      email: response.user.email,
      menus: response.user.menus,
      store: response.user.store,
      token: response.token
    } as User;
  }

  refreshToken() {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    httpHeaders.set('No-Auth', 'True');

    let formData = { "clientId": environment.clientId, "clientSecret": environment.clientSecret, "refreshToken": this.ls.getItemunic(environment.refreshTokenKey) };

    return this.httpClient.post<Token>(API_LOGIN_URL + 'refresh', formData, { headers: httpHeaders }).pipe(
      tap((response:Token) => {
        this.ls.setItemunic(environment.authTokenKey, response.access_token);
      }
    ));
  }

  getUser(userName: string): Observable<UserDetalle> {
    return this.httpClient.get<UserDetalle>(API_GET_USER + `${userName}/`);
  }
}
