import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStoreService } from '../services/local-storage.service';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent {
  toggleClass = "ft-maximize";
  public isCollapsed = true;

  constructor(
    private router: Router,
    private ls: LocalStoreService,
  ){}

  ToggleClass() {
    if (this.toggleClass === "ft-maximize") {
      this.toggleClass = "ft-minimize";
    } else this.toggleClass = "ft-maximize";
  }

  logOut() {
    this.ls.clear();
    this.router.navigateByUrl('/content-pages/login');
  }
}
