import { Component, OnInit } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { RouteInfo } from './sidebar.metadata';
import { Router, ActivatedRoute } from '@angular/router';
import {LocalStoreService} from '../services/local-storage.service';
import {environment} from '../../../environments/environment';

declare var $: any;
@Component({
    // moduleId: module.id,
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];

    constructor(
      private router: Router,
      private ls: LocalStoreService,
      private route: ActivatedRoute) {
    }

    ngOnInit() {
      /*$.getScript('./assets/js/app-sidebar.js');
      this.menuItems = ROUTES.filter(menuItem => menuItem);*/

      this.menuItems = JSON.parse(this.ls.getItemunic(environment.menuKey))
      this.menuItems = this.menuItems.filter(m => m.idParent === 0).map(m => {
        return {
          id: m.id,
          path: m.path,
          title: m.title,
          class: '',
          icon: m.icon,
          badge: '',
          badgeClass: '',
          isExternalLink: m.isExternalLink,
          submenu: []
        } ;
      }).sort(function (a, b) {
        if (a.id > b.id) {
          return 1;
        }
        if (a.id < b.id) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
    }

}
