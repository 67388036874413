// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  authTokenKey: 'authce957f57se54fs5ef54sf5se54f55',
  refreshTokenKey: 'authce957f57se54fs5ef54230424425',
  userKey: 'authce957f54d4rgd4rg4drgd5',
  menuKey: 'authMenu87585des23grt12sa',
  enterpriseKey: 'authce8954def2348sdg21',
  storeKey: 'authstore76540hg67dlfa675',
  timerKey: 'da97d7f0a07f0a7wf0af',
  clientId: 'telmovil-web-client',
  clientSecret: 'd03cd1ad-de51-4bd3-a770-41450069b0bf',
  /*api_url: 'https://api.telmovil.mx/api/'*/
  // api_url: 'https://3a5f-149-19-168-138.ngrok-free.app',
  //api_url: 'http://134.209.118.178:8088',
  api_url: 'http://165.227.182.179:8088',
  // api_url: 'http://localhost:8089',

  catUnit: 'cat-unit-00895',
  catKeyProduct: 'cat-key-product-00568',
  catDocument: 'cat-document-00569',
  catRegimen: 'cat-regimen-00570',
  catTypeEnterprise: 'cat-type-enterprise-00571',
  catPaymentMethod: 'cat-payment-method-00572',
  catCountry: 'cat-payment-method-00573',
  catSalesStatus: 'cat-sales-status-00574',
  catCurrency: 'cat-currency-00575',
  catWayPay: 'cat-way-pay-00576',
  catUseCfdi: 'cat-use-cfdi-00577',

  currencyDefault: 'MXN',
  wayPayDefault: '01',
  paymentMethodDefault: 'PUE',
  countryDefault: 'MEX',
  ivaDefault: 16,
  documentDefault: 'factura'
}
