// Angular
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { LocalStoreService } from '../../services/local-storage.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private ls: LocalStoreService, public title: Title) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.ls.getItemunic(environment.authTokenKey) != null && this.ls.getItemunic(environment.userKey) != null) {
      return true;
    } else {
      this.router.navigateByUrl('/content-pages/login');
      this.title.setTitle("Login");
      return false;
    }
  }
}