
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from "./shared/shared.module";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

import { AuthService } from './shared/auth/services/auth.service';
import { AuthGuard } from './shared/auth/guards/auth-guard.service';
import { InterceptService } from './shared/services/intercept.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { WINDOW_PROVIDERS } from './shared/services/window.service';

import * as $ from 'jquery';

@NgModule({
    declarations: [
        AppComponent,
        FullLayoutComponent,
        ContentLayoutComponent
    ],
    imports: [
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        NgbModule,
        NgxSpinnerModule,
        HttpClientModule
    ],
    providers: [
        AuthService,
        AuthGuard,
        { provide: DeviceDetectorService},
        { provide: HTTP_INTERCEPTORS, useClass: InterceptService, multi: true },
        WINDOW_PROVIDERS
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
