// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { LocalStoreService } from '../services/local-storage.service';

// JWT
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '../../shared/auth/services/auth.service';
import { Token } from '../auth/models/token.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class InterceptService implements HttpInterceptor {

	constructor(private ls: LocalStoreService, private authService: AuthService, private spinner: NgxSpinnerService,) { }

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		const helper = new JwtHelperService();
		if (request.headers.get('No-Auth') === 'True') {
			return next.handle(request.clone());
		} else {
			if (this.ls.getItemunic(environment.authTokenKey) != null) {
				this.ls.setItemunic(environment.timerKey, "0");
				if (!helper.isTokenExpired(this.ls.getItemunic(environment.authTokenKey))) {
					request = this.addAuthenticationToken(request);
				}
			}
		}

		return next.handle(request).pipe(
			catchError(error => {
				if (error instanceof HttpErrorResponse && error.status === 401) {
					//return this.handle401Error(request, next);
					this.spinner.hide();
					throw error;
				} else {
					console.log(error.message);
					throw error;
				}
			})
		);
	}

	addAuthenticationToken(request: HttpRequest<any>) {
		return request.clone({
			setHeaders: {
				Authorization: `Bearer ${this.ls.getItemunic(environment.authTokenKey)}`
			}
		});
	}

	handle401Error(request: HttpRequest<any>, next: HttpHandler) {
		return this.authService.refreshToken().pipe(
			switchMap((response: Token) => {
				return next.handle(this.addAuthenticationToken(request));
			})
		)
	}
}
