import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class LocalStoreService {

  private ls = window.localStorage;
  private secretKey = 'telmovil';

  constructor() { }

  public encrypt(value: string): string {
    return CryptoJS.AES.encrypt(value, this.secretKey.trim()).toString();
  }

  public decrypt(textToDecrypt: string) {
    return CryptoJS.AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(CryptoJS.enc.Utf8);
  }

  public setItem(key: string, value: any) {
    const valorEncryptado = this.encrypt(JSON.stringify(value));
    this.ls.setItem(key, valorEncryptado)
  }
  
  public setItemunic(key: string, value: any) {
    const valorEncryptado = this.encrypt(value);
    this.ls.setItem(key, valorEncryptado);
  }

  public getItem(key: string) {
    let value = this.ls.getItem(key)
    try {
      return JSON.parse(this.decrypt(value))
    } catch (e) {
      return null
    }
  }

  public getItemunic(key: string) {
    let value = this.ls.getItem(key);
    if (value) {
      value = this.decrypt(value);
    }
    return value;
  }
  
  public removeItemunic(key: string) {
    localStorage.removeItem(key);
  }

  public clear() {
    this.ls.clear();
  }
}