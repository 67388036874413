<!-- Navbar (Header) Starts -->
<nav class="header-navbar navbar navbar-expand-lg navbar-light bg-faded">
    <div class="container-fluid">
        <div class="navbar-header">
            <button type="button" class="navbar-toggle d-lg-none float-left" data-toggle="collapse">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <span class="d-lg-none navbar-right navbar-collapse-toggle">
                <a class="open-navbar-container" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="navbarSupportedContent">
                    <i class="ft-more-vertical"></i>
                </a>
            </span>
            <a href="javascript:;" class="mr-2 display-inline-block" id="navbar-fullscreen" appToggleFullscreen (click)="ToggleClass()">
                <i class="{{toggleClass}} blue-grey darken-4"></i>
                <p class="d-none">fullscreen</p>
            </a>
            <!-- TODO -->
            <!-- a class="ml-2 display-inline-block">
                <i class="ft-shopping-cart blue-grey darken-4"></i>
                <p class="d-none">cart</p>
            </a -->
            <!--<div ngbDropdown placement="bottom-left" class="ml-2 display-inline-block">
                <a class="nav-link position-relative" id="apps" ngbDropdownToggle>
                    <i class="ft-edit blue-grey darken-4"></i>
                    <span class="mx-1 blue-grey darken-4 text-bold-400">Apps</span>
                </a>
                <div ngbDropdownMenu aria-labelledby="apps" class="apps">
                    <div class="arrow_box">
                        <a class="dropdown-item py-1" href="javascript:;">
                            <span>Chat</span>
                        </a>
                        <a class="dropdown-item py-1" href="javascript:;">
                            <span>TaskBoard</span>
                        </a>
                        <a class="dropdown-item py-1" href="javascript:;">
                            <span>Calendar</span>
                        </a>
                    </div>
                </div>
            </div>-->
        </div>
        <div class="navbar-container">
            <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngbCollapse]="isCollapsed">
                <ul class="navbar-nav">
                    <li class="nav-item mt-1 navbar-search text-left" ngbDropdown display="dynamic" placement="bottom-right">
                        <a id="search" class="nav-link" ngbDropdownToggle>
                            <i class="ft-search blue-grey darken-4"></i>
                        </a>
                        <div ngbDropdownMenu aria-labelledby="search" class="search">
                            <div class="arrow_box_right">
                                <form class="navbar-form navbar-right" role="search">
                                    <div class="position-relative has-icon-right mb-0">
                                        <input type="text" class="form-control" placeholder="Search" id="navbar-search">
                                        <div class="form-control-position navbar-search-close">
                                            <i class="ft-x"></i>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </li>
                    <li class="nav-item mt-1" ngbDropdown  display="dynamic" placement="bottom-right" >
                        <a class="nav-link position-relative" id="dropdownBasic2" ngbDropdownToggle>
                            <i class="ft-bell blue-grey darken-4"></i>
                            <!-- TODO -->
                            <!-- <span class="notification badge badge-pill badge-danger">4</span> -->
                            <p class="d-none">Notifications</p>
                        </a>
                        <!-- TODO -->
                        <!-- div ngbDropdownMenu aria-labelledby="dropdownBasic2" class="notification-dropdown">
                            <div class="arrow_box_right">
                                <div class="noti-list">
                                    <a class="dropdown-item noti-container py-2">
                                        <i class="ft-share info float-left d-block font-medium-4 mt-2 mr-2"></i>
                                        <span class="noti-wrapper">
                                            <span class="noti-title line-height-1 d-block text-bold-400 info">New Order Received</span>
                                            <span class="noti-text">Lorem ipsum dolor sit ametitaque in, et!</span>
                                        </span>
                                    </a>
                                    <a class="dropdown-item noti-container py-2">
                                        <i class="ft-save warning float-left d-block font-medium-4 mt-2 mr-2"></i>
                                        <span class="noti-wrapper">
                                            <span class="noti-title line-height-1 d-block text-bold-400 warning">New User Registered</span>
                                            <span class="noti-text">Lorem ipsum dolor sit ametitaque in </span>
                                        </span>
                                    </a>
                                    <a class="dropdown-item noti-container py-2">
                                        <i class="ft-repeat danger float-left d-block font-medium-4 mt-2 mr-2"></i>
                                        <span class="noti-wrapper">
                                            <span class="noti-title line-height-1 d-block text-bold-400 danger">New Order Received</span>
                                            <span class="noti-text">Lorem ipsum dolor sit ametest?</span>
                                        </span>
                                    </a>
                                    <a class="dropdown-item noti-container py-2">
                                        <i class="ft-shopping-cart success float-left d-block font-medium-4 mt-2 mr-2"></i>
                                        <span class="noti-wrapper">
                                            <span class="noti-title line-height-1 d-block text-bold-400 success">New Item In Your Cart</span>
                                            <span class="noti-text">Lorem ipsum dolor sit ametnatus aut.</span>
                                        </span>
                                    </a>
                                    <a class="dropdown-item noti-container py-2">
                                        <i class="ft-heart info float-left d-block font-medium-4 mt-2 mr-2"></i>
                                        <span class="noti-wrapper">
                                            <span class="noti-title line-height-1 d-block text-bold-400 info">New Sale</span>
                                            <span class="noti-text">Lorem ipsum dolor sit ametnatus aut.</span>
                                        </span>
                                    </a>
                                    <a class="dropdown-item noti-container py-2">
                                        <i class="ft-box warning float-left d-block font-medium-4 mt-2 mr-2"></i>
                                        <span class="noti-wrapper">
                                            <span class="noti-title line-height-1 d-block text-bold-400 warning">Order Delivered</span>
                                            <span class="noti-text">Lorem ipsum dolor sit ametnatus aut.</span>
                                        </span>
                                    </a>
                                </div>
                                <a class="noti-footer primary text-center d-block border-top border-top-blue-grey border-top-lighten-4 text-bold-400 py-1">Read All Notifications</a>
                            </div>
                        </div -->
                    </li>
                    <li class="nav-item mr-0" ngbDropdown display="dynamic" placement="bottom-right">
                        <a class="nav-link dropdown-user-link position-relative" id="dropdownBasic3" ngbDropdownToggle>
                            <span class="avatar avatar-online">
                                <img src="assets/img/portrait/small/avatar-s-3.jpeg" alt="avatar" id="navbar-avatar">
                            </span>
                            <p class="d-none">User Settings</p>
                        </a>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic3">
                            <div class="arrow_box_right">
                                <!-- <a class="dropdown-item py-1" routerLink="/pages/profile">
                                    <i class="ft-edit mr-2"></i>
                                    <span>My Profile</span>
                                </a>
                                <a class="dropdown-item py-1" routerLink="/chat">
                                    <i class="ft-message-circle mr-2"></i>
                                    <span>My Chat</span>
                                </a>
                                <a class="dropdown-item py-1" href="javascript:;">
                                    <i class="ft-settings mr-2"></i>
                                    <span>Settings</span>
                                </a> -->
                                <!-- <div class="dropdown-divider"></div> -->
                                <!-- <a class="dropdown-item" href="javascript:;">
                                    <i class="ft-power mr-2"></i>
                                    <span>Logout</span>
                                </a> -->
                                <button type="button" class="dropdown-item" (click)="logOut()">
                                    <i class="ft-power mr-2"></i>
                                    <span>Logout</span>
                                </button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>
<!-- Navbar (Header) Ends -->
